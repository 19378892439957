import React from 'react';
// import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
// import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
// import { ReactComponent as IconGithub } from './assets/icons/github.svg';
import  LogoAidIot from './assets/icons/Aid-IOT_image_and_botton_name_240x240.png';
import "./App.css";
import ParticlesComponent from './Particles';

class App extends React.Component {
  render = () => {
    return (
      <div id='root'>
   

      
      <div className="card">
      <ParticlesComponent/>


        <div className="header">
          <div className="logo">
            {/* <a href="www.aid-iot.com" target="_blank" rel="noopener noreferrer">
            <LogoAidIot className="icon" /> */}
            {/* </a> */}
            <img src={LogoAidIot}  alt="logo" width={100} height={100}
             />
          </div>
          {/* <div className="social">
            <a href="https://facebook.com" title="Facebook" target="_blank" rel="noopener noreferrer">
              <IconFacebook className="icon" />
            </a>
            <a href="https://twitter.com" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconTwitter className="icon" />
            </a>

          </div> */}
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Get ready for the change.</h1>
            <p>Website coming soon. Please check back to know more. Shoot us an email if you're curious.</p>
          </div>
          <a href="mailto:admin@aid-iot.com">
            <div className="cta">Send us an email</div>
          </a>
        </div>
        <div className="footer">
          <span>All Rights Reserved ©<a className="underlined" href="https://github.com/AID-IOT" target="_blank" rel="noopener noreferrer">AID-IOT</a> </span>
        </div>
      </div>

      </div>
    );
  }
}

export default App;